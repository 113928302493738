body, html {
  overscroll-behavior: contain !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.navbar .left img {
  max-width: 200px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.list.item-details {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.list.item-details.narrow {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 24px;
}

.list.item-details.narrow ul {
  padding-bottom: 12px;
  margin-bottom: 0px;
}

.list.item-details.narrow li.media-item .item-content, 
.list.item-details.narrow li.media-item .item-content .item-inner {
  padding: 6px 12px 6px 12px;
  margin: 0;
  height: 32px;
  min-height: 32px;
}

.list.item-details .item-after{
  font-size: 14px;
  font-weight: bold;
  color: black;
}

.list.sidebar {
  background-color: #e3e3e3;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
}

.active.media-item {
  background-color: #d3d3d3;
}

.navbar .navbar-bg {
  background-color: black;
}

.navbar .navbar-inner {
  color: white;
}

.dashboard-box {
  width: calc(50vw - 24px);
  height: calc(50vw - 24px);
  margin-left: 12px;
  margin-top: 12px;
  background: #d3d3d3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  border-radius: 12px;
  text-transform: uppercase;
}

.dashboard-box.disabled {
  opacity: .20!important;
  pointer-events: none!important;
}


.m-l-6 {
  margin-left: 6px;
}

.m-l-12 {
  margin-left: 12px;
}

.m-l-24 {
  margin-left: 24px;
}

.m-r-6 {
  margin-right: 6px;
}

.m-r-12 {
  margin-right: 12px;
}


.m-r-24 {
  margin-right: 24px;
}

.f-1 {
  flex: 1;
}


.ta-r {
  text-align: right;
}

.stronger {
  font-weight: bold;
}

li.stronger a.item-link.popover-close .item-content .item-inner .item-title {
  font-weight: bold !important;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .dashboard-box {
    font-size: 18px;
  } 
}

.first-collected {
  background-color: #d3d3d3;
}

.product-scan-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100% - 68px);
}

.product-scan-placeholder img {
  margin: 0 auto 48px auto;
  max-width: 200px;
}

.product-scan-placeholder span {
  font-size: 24px;
}